import * as LoginApi from './login'
import * as ClientApi from './client'
import * as HistoryData from './historyData'
import * as CommonAPI from './common'
import * as Country from './country'
import * as AbCurrency from './abCurrency'
import * as consignment from './consignment'
import * as vmiTransfer from './vmiTransfer'
import * as invHistory from './invHistory'
import * as Auth from './auth'
import * as HistoryExport from './historyData/export'
import * as Password from './password'


export default {
  ...LoginApi,
  ...ClientApi,
  ...HistoryData,
  ...CommonAPI,
  ...Country,
  ...AbCurrency,
  ...consignment,
  ...vmiTransfer,
  ...invHistory,
  ...Auth,
  ...HistoryExport,
  ...Password
}