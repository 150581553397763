import {SET_QUERY_SHOW_KEY_SPLIT} from '@constant'
import router from '../router'
import {SEARCH_FORM_NUMRANGE} from "../constant";

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) s++
    else if (code > 0x7ff && code <= 0xffff) s += 2
    if (code >= 0xDC00 && code <= 0xDFFF) i--
  }
  return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = []
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i])
    }
  }
  return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return ''
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) return ''
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement('div')
  div.innerHTML = val
  return div.textContent || div.innerText
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== 'object') {
    target = {}
  }
  if (Array.isArray(source)) {
    return source.slice()
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property]
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty)
    } else {
      target[property] = sourceProperty
    }
  })
  return target
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return
  }
  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += '' + className
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr))
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * 日期格式化"YYYY-MM-DD hh:mm:ss""
 */
export function dateFormat(fmt, date) {
  let ret;
  let inputData = new Date(date);
  const opt = {
    "y+": inputData.getFullYear().toString(), // 年
    "M+": (inputData.getMonth() + 1).toString(), // 月
    "d+": inputData.getDate().toString(), // 日
    "h+": inputData.getHours().toString(), // 时
    "m+": inputData.getMinutes().toString(), // 分
    "s+": inputData.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length === 1
          ? opt[k]
          : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
}

/*
* 判断选择的类型
* @param value 值
* @return 类型
* */
function judgeCriterionType(value = ''){
  if(value.includes('=') && value.startsWith('=')){
    if(value === '='){
      //等于空
      return 'NO_VALUE'
    }else {
      //等于
      return 'LIST_VALUE'
    }
  }else if(value.includes('<>') && value.startsWith('<>')){
    if(value === '<>'){
      //不等于空
      return 'NOT_EQUAL_VALUE'
    }else {
      //不等于
      return 'NOT_EQUAL_LIST_VALUE'
    }
  }else if(value.includes('<') && value.includes('>') && (value.startsWith('>') || value.startsWith('<'))){
    if(value.indexOf('<') < value.indexOf('>')){
      //不介于 之间
      return 'NOT_BETWEEN_VALUE'
    }else{
      //介于  之间
      return 'BETWEEN_VALUE'
    }
  }else if(value.includes('>') && value.startsWith('>')){
    //大于
    return 'GREATER_THAN'
  }else if(value.includes('<') && value.startsWith('<')){
    //小于
    return 'LESS_THAN'
  }
}

/*
* 根据符号确定类型
* @param info
* */
function setSearchValueType(info={}){
  let result = {
    VALUE:'',
    CRITERION_TYPE: '',
    CONDITION:info.CONDITION,
    SECOND_VALUE:''
  }
  if(typeof info.VALUE !== 'string') return result
  result.CRITERION_TYPE = judgeCriterionType(info.VALUE)|| 'LIKE_SINGLE_VALUE'
  let valueData = info.VALUE || ''
  if (result.CRITERION_TYPE === 'LIST_VALUE') {
    let equalData = valueData.replace(/\=/g,'')
    result.VALUE = equalData.split(SET_QUERY_SHOW_KEY_SPLIT)
    return result
  } else if(result.CRITERION_TYPE === 'NO_VALUE'){
    result.VALUE = ''
    return result
  }else if (result.CRITERION_TYPE === 'NOT_EQUAL_LIST_VALUE') {
    let equalData = valueData.replace(/\<\>/g,'')
    result.VALUE = equalData.split(SET_QUERY_SHOW_KEY_SPLIT)
    return result
  } else if(result.CRITERION_TYPE === 'NOT_EQUAL_VALUE'){
    result.VALUE = ''
    return result
  }else if(result.CRITERION_TYPE === 'BETWEEN_VALUE'){
    let betweenValue = valueData.replace(/\>/g,'')
    let betweenValueList = betweenValue.split('<')
    result.VALUE = betweenValueList[0]
    result.SECOND_VALUE = betweenValueList.length>1 ? betweenValueList[1] : ''
    return result
  }else if(result.CRITERION_TYPE === 'NOT_BETWEEN_VALUE'){
    let betweenValue = valueData.replace(/\</g,'')
    let betweenValueList = betweenValue.split('>')
    result.VALUE = betweenValueList[0]
    result.SECOND_VALUE = betweenValueList.length>1 ? betweenValueList[1] : ''
    return result
  }else if(result.CRITERION_TYPE === 'GREATER_THAN'){
    result.VALUE = valueData.replace(/\>/g,'')
    return result
  }else if(result.CRITERION_TYPE === 'LESS_THAN'){
    result.VALUE = valueData.replace(/\</g,'')
    return result
  }else{
    result.VALUE = info.VALUE
    return result
  }
}


/**
 *
 * @param {Array} list 配置数组
 * @param {Object} formData  表单值
 *
 * @returns {Array}
 * valueType
 * "BETWEEN_VALUE", value = "查询两种之间的数据"
 * "SINGLE_VALUE", value = "单个值"
 * "LIKE_SINGLE_VALUE", value="单个值模糊查询"
 * "LIST_VALUE", value = "多个值,传入一个集合"
 * "GREATER_THAN" value = "大于"
 * "LESS_THAN" value = '小于'
 * 不等于：NOT_EQUAL_LIST_VALUE，多个值，集合
 * 等于 <空>  ： NO_VALUE
 * 不等于<空> ： NOT_EQUAL_VALUE
 * 不介于…和….：NOT_BETWEEN_VALUE
 * */
export function getSearchValueList(list, formData) {
  const result = []
  for (const key in formData) {
    /*
    * info 下字段
    * VALUE 参数值
    * CRITERION_TYPE  查询类型
    * CONDITION  参数key值
    * SECOND_VALUE  区间值的第二个参数
    * */
    let info = {}, formDataValue = formData[key]
    let item = list.find(item => key === item.key) || {}
    info.CONDITION = key
    if (item.type === 'magnifier') {
      info.VALUE = formDataValue
      info = setSearchValueType(info)
      if (Array.isArray(info.VALUE)) {
        if (info.VALUE.length) {
          result.push(info)
        }
      } else {
        if (info.VALUE) {
          result.push(info)
        }
      }
      if(['NOT_EQUAL_VALUE','NO_VALUE'].includes(info.CRITERION_TYPE)){
        result.push(info)
      }
      continue;
    }

    if (item.type === 'numrange') {   //数字范围  数组第一个值为 CRITERION_TYPE  第二个值 为数字
      if (Array.isArray(formDataValue)) {
        if (formDataValue.length > 1 && formDataValue.every(item => !!item)) {
          info.CRITERION_TYPE = formDataValue[0]
          info.VALUE = formDataValue[1]
          result.push(info)
        }
      }
      continue;
    }

    if(item.type === 'multiselect'){   //下拉框 多选 查询
      item.valueType = 'LIST_VALUE'
    }
    if (item.type === 'daterange') {
      item.valueType = 'DATE_BETWEEN_VALUE'
    }
    if (item.valueType === 'DATE_BETWEEN_VALUE' || item.valueType === 'BETWEEN_VALUE') {  //区间查询 值为数组的  取的是区间值 需要给secondValue
      if (Array.isArray(formDataValue)) {
        if (formDataValue.length) {
          info.VALUE = formDataValue[0]
          info.SECOND_VALUE = formDataValue[1]
          info.CRITERION_TYPE = item.valueType
        }
      }
    } else {
      info.VALUE = formDataValue
      info.CRITERION_TYPE = item.valueType || 'LIKE_SINGLE_VALUE'
    }

    if (Array.isArray(info.VALUE)) {
      if (info.VALUE.length) {
        result.push(info)
      }
    } else {
      if (info.VALUE) {
        result.push(info)
      }
    }
  }
  return result
}

export function getTableColumnList() {

}

/*
* 根据key或者value获取最终的list
*
* @param {String} keyList
* @param {Array} condition
* @returns {Array}
* */
export function keyToConfigList(keyList = '', condition = [], props = 'key') {
  if (typeof keyList !== 'string') return []
  let keyArr = keyList.split(SET_QUERY_SHOW_KEY_SPLIT),
    resultArr = [];
  keyArr.forEach(item => {
    condition.forEach(child => {
      if (child[props] === item) {
        resultArr.push(child)
      }
    })
  })
  return resultArr
}


/*
* 参数显示块
* @param {block} 显示的位置  searchInfo：只在查询条件展示，tableList：表格中展示，基本信息中展示,dataInfo:详情中展示， all：所有
* @param {value} 参数值
* @returns Boolean
* */
export function isCanShowBlock(bolck = '', value = []) {
  const SEARCHINFO = 'searchInfo', TABLELIST = 'tableList', DATAINFO = 'dataInfo', ALL = 'all'
  if (!Array.isArray(value)) return false
  switch (bolck) {
    case SEARCHINFO:
      return value.includes('all') || value.includes('searchInfo')
    case TABLELIST:
      return value.includes('all') || value.includes('tableList')
    case DATAINFO:
      return value.includes('all') || value.includes('dataInfo')
  }
}


export function removeAuthInfo() {
  window.sessionStorage.AUTH_ERROR = null
  window.sessionStorage.AUTH_SESSION_ID = null
  window.sessionStorage.AUTH_STATUS = null
  window.sessionStorage.USER_ID = null
  window.sessionStorage.PERMISSION_LIST = null
  window.sessionStorage.MENU_DATA = null
}

 /**
 * @description 目录递归扁平化
 * @param {Array} []
 * @return {Array} []
 */
export function recursion(arr,key = 'children') {
  const loop = function (arr, result = []) {
    if (Array.isArray(arr) && arr.length > 0 ) {
      arr.forEach(item => {
        result.push(item);
        loop(item[key], result);
      })
    }
    return result;
  };
  return loop(arr)
}

export function getPermissionList(data) {
  let MENU_DATA = []
  let PERMISSION_LIST = []
  let listData = recursion(data,'SUBMENUS') || []
  MENU_DATA = listData.filter(item=> item.TYPE === 'MENU')
  PERMISSION_LIST = listData.filter(item=> item.TYPE === 'POINT')
  return {
    PERMISSION_LIST,
    MENU_DATA
  }
}


export const noop = function () {
}

