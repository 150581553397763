export const MOVE_TASK_STATUS = [
  { label: '合并', key: 'Consol', en: 'Consol' },
  { label: '完成', key: 'Complete', en: 'Complete' },
  { label: '到期日为', key: 'Due In', en: 'Due In' },
  { label: '错误', key: 'Error', en: 'Error' },
  { label: '保留', key: 'Hold', en: 'Hold' },
  { label: '进行中', key: 'In Progress', en: 'In Progress' },
  { label: '未决', key: 'Pending', en: 'Pending' },
  { label: '预收', key: 'Pre-Received', en: 'Pre-Received' },
  { label: '发布', key: 'Released', en: 'Released' },
];

export const MOVE_TASK_TYPE = [
  { label: '合并头', key: 'Consol Header' },
  { label: '', key: 'Due In' },
  { label: '套装', key: 'Kitting' },
  { label: '订单', key: 'Order' },
  { label: '集货头', key: 'Marshal Header' },
  { label: '拣选面合并头', key: 'Pick Face Consol Header' },
  { label: '上架', key: 'Putaway' },
  { label: '重定位', key: 'Relocation' },
  { label: '补货', key: 'Replenishment' },
  { label: 'UPI 头', key: 'UPI Header' },
  { label: '增值服务', key: 'VAS' },
];
/**
 * @description  新描述 移动任务类型
*/
export const NEW_MOVE_TASK_TYPE = [
  { label: '重新打包', en: 'Repack', key: 'B' },
  { label: '合并表头', en: 'Consol Header', key: 'C' },
  { label: '到期日为', en: 'Due In', key: 'D' },
  { label: '空托盘', en: 'Empty Pallet', key: 'E' },
  { label: '拣选面合并头', en: 'Pick Face Consol Header', key: 'F' },
  { label: '套装', en: 'Kitting', key: 'K' },
  { label: '重定位', en: 'Relocation', key: 'M' },
  { label: '订单', en: 'Order', key: 'O' },
  { label: '上架', en: 'Putaway', key: 'P' },
  { label: '补货', en: 'Replenishment', key: 'R' },
  { label: '集货表头', en: 'Marshal Header', key: 'T' },
  { label: 'UPI 表头', en: 'UPI Header', key: 'U' },
  { label: '增值服务', en: 'VAS', key: 'V' },
];


export const MOVE_TASK_QC_STATUS = [
  { label: '没有批次控制', key: '' },
  { label: 'qc 保留', key: 'Hold' },
  { label: 'qc 释放', key: 'Released' },
  { label: 'qc 拒绝', key: 'Rejected' },
];


