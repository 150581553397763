import axios from 'axios'
import { VueAxios } from './axios'
import { Notification, Message, Loading } from 'element-ui';
import {removeAuthInfo} from '@utils'
import router from "../router";
import { LoadingOptions } from '@/pluginConfig/loadingConfig.js';
import {backApis} from './loadingBackApis'

const noop = function(){}

let loadingInstance = {
  close:noop
};

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 1000000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = window.sessionStorage.getItem('AUTH_SESSION_ID')
    Notification.error({
      title: error.response.status,
      message: data.message
    })
    if(error.response.status === 403){
      loadingInstance.close();
      return router.push(`/login?redirect=${router.currentRoute.fullPath}`)
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  if(!backApis.includes(config.url)){
    loadingInstance = Loading.service(LoadingOptions);
  }
  const token = window.sessionStorage.getItem('AUTH_SESSION_ID');


  // 针对时间类型进行特殊的 type 定义
  if (config.data && config.data.CRITERION_LIST) {
    config.data.CRITERION_LIST.map(item => {
      if (item.CONDITION.indexOf('TIME') > -1) {
        item.CRITERION_TYPE = 'DATE_HMS_SINGLE_VALUE';
      }
      return item
    })
  }

  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['X-Auth-Token'] = token
  }else{
    if(config.url !== '/application/login') {
      Message.error('未登录')
      router.push(`/login?redirect=${router.currentRoute.fullPath}`)
    }
  }

  return config
}, errorHandler)


/*
* 200 ok 好
* 400 error 错
* 401 Unauthorized 重新认证
* */

// response interceptor
request.interceptors.response.use((response) => {
  loadingInstance.close();
  if(response.data.status === 400){
    Message.error(response.data.message || response.data.data.ERROR)
  }else if(response.data.status === 401){
    Message.error(response.data.message || response.data.data.ERROR)
    router.push(`/login?redirect=${router.currentRoute.fullPath}`)
    removeAuthInfo()
  } 
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
