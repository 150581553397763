export const ORDER_HEADER_STATUS = [
  { label: '已分配的', key: 'Allocated' },
  { label: '等待', key: 'Awaiting' },
  { label: '已取消', key: 'Cancelled' },
  { label: '完成', key: 'Complete' },
  { label: '已交货', key: 'Delivered' },
  { label: '职责错误', key: 'Duty Error' },
  { label: '保留', key: 'Hold' },
  { label: '进行中', key: 'In Progress' },
  { label: '运输中', key: 'In Transit' },
  { label: '套装', key: 'Kitting' },
  { label: '已打包的', key: 'Packed' },
  { label: '已拣选的', key: 'Picked' },
  { label: '准备装载', key: 'Ready to Load' },
  { label: '发布', key: 'Released' },
  { label: '已运送', key: 'Shipped' }
]

export const ORDER_HEADER_ORDER_TYPE = [
  { label: '套件存储', key: 'kittostock' },
  { label: '非套件存储', key: 'unkittostock' }
]

export const ORDER_HEADER_MOVE_TASK_STATUS = [
  { label: '释放', key: 'Release' },
  { label: '冻结', key: 'Hold' }
]



