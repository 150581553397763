/**
 * @file 导出
*/
import request from '@/utils/request';

// move task
export const ExportMoveTask = parameter => request.post('/move/export-move-task-inventory-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// order header
export const ExportOrderHeader = parameter => request.post('/order/export-order-header-by-order-header-vo',  parameter, { responseType:'blob' });
// order line
export const ExportOrderLine = parameter => request.post('/order/export-client-by-criterion',  parameter, { responseType:'blob' });

// pre-advice header
export const ExportPreAdviceHeader = parameter => request.post('/preadvice/export-pre-advice-header-by-advice-header-vo',  parameter, { responseType:'blob' });
// pre-advice line
export const ExportPreAdviceLine = parameter => request.post('/preadvice/export-pre-advice-line-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// Upi header
export const ExportUPIHeader = parameter => request.post('/upi/export-upi-receipt-header-by-criterion-bean-vo',  parameter, { responseType:'blob' });
// Upi line
export const ExportUPILine = parameter => request.post('/upi/export-upi-receipt-line-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// vmi transfer
export const ExportVMITransfer = parameter => request.post('/vmi/export-vmi-transfer-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// consignment
export const ExportConsigment = parameter => request.post('/consignment/export-consignment-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// 库存事务
export const ExportInventory = parameter => request.post('/inventory/export-inventory-transaction-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// 出库单
export const ExportShipping = parameter => request.post('/shipping/export-shipping-manifest-by-criterion-bean-vo',  parameter, { responseType:'blob' });

// 历史库存查询
export const ExportCextension = parameter => request.post('/cextension/export-c-inventory-by-criterion-bean-vo',  parameter, { responseType:'blob' });
//历史汇总库存查询
export const ExportTotlCextension = parameter => request.post('/cextension/export-c-inventorysummary-by-criterion-bean-vo',  parameter, { responseType:'blob' });

