
const state = {
  order:{
    currentTab:'headCom',
    currentHeaders:[]
  },
  preAdvice:{
    currentTab:'headCom',
    currentHeaders:[]
  },
  upi:{
    currentTab:'headCom',
    currentHeaders:[]
  }
}

const actions = {

}

const mutations = {
  SET_CURRENT_TAB(state,{model,currentTab}){
    state[model].currentTab = currentTab
  },
  SET_CURRENT_HEADERS(state,{model,header}){
    state[model].currentHeaders = header
  }
}

export default {
  state,
  mutations,
  actions
}
