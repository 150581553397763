import Vue from 'vue'
import 'xe-utils'
import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'

/**
 * @description 添加一个render的 cell-render
*/
VXETable.renderer.mixin({
  "render": {
    renderDefault(h, renderOpts, params) {
      let { row, column } = params;
      let { events } = renderOpts;
      return [
        // h(
        //   "com-functionRender",
        //   {
        //     props: {
        //       render: column.cellRender.props.render,
        //       data: row
        //     }
        //   }
        // ),
        <el-button type="text" onClick={() => events.click('status', row)}>启用</el-button>,
        <el-button type="text" onClick={() => events.click('detail', row)}>详情</el-button>,
        <el-button type="text" onClick={() => events.click('edit', row)}>编辑</el-button>
      ];
    }
  }
})

Vue.use(VXETable)