export const CLIENT = [
  { label: 'HSSY环盛商业', key: 'HSSY' },
  { label: 'BS百盛', key: 'BS' },
  { label: 'MTGF魔提工坊', key: 'MTGF' }
]

export const CLIENT_STATUS = [
  { label: '启用', key: 'Able' },
  { label: '停用', key: 'Disabled' },
  { label: '处理中', key: 'Pending' }
]
  
export const ClientTimeUnit = [
  {label: "DD-MM-YY", key: "DD-MM-YY"},
  {label: "DD-MM-YYYY", key: "DD-MM-YYYY"},
  {label: "DD-MON-YY", key: "DD-MON-YY"},
  {label: "DD-MON-YYYY", key: "DD-MON-YYYY"},
  {label: "DDMMYY", key: "DDMMYY"},
  {label: "DDMMYYYY", key: "DDMMYYYY"},
  {label: "DDMONYY", key: "DDMONYY"},
  {label: "DDMONYYYY", key: "DDMONYYYY"},
  {label: "MMDDYY", key: "MMDDYY"},
  {label: "MMDDYYYY", key: "MMDDYYYY"},
  {label: "MONDDYY", key: "MONDDYY"},
  {label: "MONDDYYYY", key: "MONDDYYYY"},
  {label: "YY-MM-DD", key: "YY-MM-DD"},
  {label: "YY-MON-DD", key: "YY-MON-DD"},
  {label: "YYMMDD", key: "YYMMDD"},
  {label: "YYMONDD", key: "YYMONDD"},
  {label: "YYYY-MM-DD", key: "YYYY-MM-DD"},
  {label: "YYYY-MON-DD", key: "YYYY-MON-DD"},
  {label: "YYYYMMDD", key: "YYYYMMDD"},
  {label: "YYYYMONDD", key: "YYYYMONDD"}
]

export const DISALLOW_SHORT_SHIP_OPTIONS = [
  { label: '全部', key: 'Full' },
  { label: '部分', key: 'Partial' },
]