/** 
 * @description: 时间计算 
*/
import $ from 'jquery';
import { dateFormat } from '@utils/utils';

export default {
  inserted: function (el, binding, vnode) {
    const { value: _obj } = binding
    const { context: that, data } = vnode
    const { props } = data

    if(that && that._isVue) {
      const $this = $($(el).children('input')[0])[0]

      $this.onchange = function(el) {
        let count = Number(el.target.value);
        if (/^-?[0-9]\d*$/.test(count)) {
          let val = new Date().getTime() + (count * 24 * 60 * 60 * 1000)
          that.formData[_obj] = dateFormat(props['value-format'] || 'yyyy-MM-dd', val);
        }
      }
    }  
  }
}