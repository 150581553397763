export const CONSIGNMENT_STATUS = [
  { label: '已分配的', key: 'Allocated' },
  { label: '等待', key: 'Awaiting' },
  { label: '已取消', key: 'Cancelled' },
  { label: '完成', key: 'Complete' },
  { label: '创建', key: 'Created' },
  { label: '已交货', key: 'Delivered' },
  { label: '职责错误', key: 'DutyError' },
  { label: '保留', key: 'Hold' },
  { label: '进行中', key: 'InProgress' },
  { label: '运输中', key: 'InTransit' },
  { label: '套装', key: 'Kitting' },
  { label: '已打包的', key: 'Packed' },
  { label: '已拣选', key: 'Picked' },
  { label: '准备装载', key: 'ReadyToLoad' },
  { label: '发布', key: 'Released' },
  { label: '已运送', key: 'Shipped' },
];