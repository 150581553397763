/**
 * @description: 默认大写
 */
import $ from 'jquery';

export default {
  inserted: function (el, binding, vnode) {
    const { value } = binding
    const {context, data:{attrs}} = vnode
    if(attrs.defaultUpper){
      const $this = $($(el).find('input')[0])[0]

      const toUpperCaseInput = function (data){
        const reg = /[a-zA-Z]/g;
        const flag = reg.test(data)
        if(flag){
          context.formData[value] = data.toUpperCase();
        }
      }

      $this.onkeyup = function(event) {
        if(event.keyCode === 20){
          $(this).removeAttr('defaultupper')
          delete attrs.defaultUpper
        }
      }
      $this.onpropertychange = function(event) {
        if(attrs.defaultUpper){
          toUpperCaseInput(event.target.value)
        }
      }
      $this.oninput = function (event){
        if(attrs.defaultUpper){
          toUpperCaseInput(event.target.value)
        }
      }
    }
  }
}