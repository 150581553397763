/**
 * @description 指令注册, 在根目录下执行以下命令
 * @command npm run directives || yarn directives
 * */
import Vue from 'vue';

import dateCount from './dateCount/dateCount.js';
import hasPermission from './hasPermission/hasPermission'
import upCase from './upCase/upCase'

Vue.directive('dateCount', dateCount);
Vue.directive("hasPermission", hasPermission);
Vue.directive("upCase", upCase);