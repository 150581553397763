<template>
  <div>
    <template v-if="!item.children">
      <app-link :to="item.path">
        <el-menu-item :class="{'submenu-title-noDropdown':!isNest}" :index="item.path">
          <item :icon="item.icon" :title="item.meta.title" />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu v-else ref="subMenu" popper-append-to-body :index="item.path">
      <template slot="title">
        <item :icon="item.icon" :title="item.meta.title" />
      </template>
      <sidebar-item
        v-if="!child.hidden"
        v-for="child in item.children"
        :key="child.path"
        :is-nest="true"
        :item="child"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import Item from './Item'
import FixiOSBug from './FixiOSBug'
import AppLink from './Link'

export default {
  name: 'SidebarItem',
  components: { Item,AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
