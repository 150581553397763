import request from '@/utils/request';


export const clientMagnifierList = parameter => request.post('/client/select-magnifier-client-by-magnifier-criterion',  parameter);

export const orderLineMagnifierList = parameter => request.post('/order/select-magnifier-order-line-by-magnifier-criterion',  parameter);

export const orderHeaderMagnifierList = parameter => request.post('/order/select-magnifier-order-header-by-magnifier-criterion',  parameter);

export const preHeaderMagnifierList = parameter => request.post('/preadvice/select-magnifier-pre-advice-header-by-magnifier-criterion',  parameter);

export const preLineMagnifierList = parameter => request.post('/preadvice/select-magnifier-pre-advice-line-by-magnifier-criterion',  parameter);

export const upiHeaderMagnifierList = parameter => request.post('/upi/select-magnifier-upi-receipt-header-by-magnifier-criterion',  parameter);

export const upiLineMagnifierList = parameter => request.post('/upi/select-magnifier-upi-receipt-line-by-magnifier-criterion',  parameter);

export const vmiMagnifierList = parameter => request.post('/vmi/select-magnifier-vmi-transfer-by-magnifier-criterion',  parameter);

export const consignmentMagnifierList = parameter => request.post('/consignment/select-magnifier-consignment-by-magnifier-criterion',  parameter);

export const shipmentMagnifierList = parameter => request.post('/shipping/select-magnifier-shipping-manifest-by-magnifier-criterion',  parameter);

export const moveTaskMagnifierList = parameter => request.post('/move/select-magnifier-move-task-inventory-by-magnifier-criterion',  parameter);

export const inventoryMagnifierList = parameter => request.post('/inventory/select-magnifier-inventory-transaction-by-magnifier-criterion',  parameter);

export const CInventoryMagnifierList = parameter => request.post('/cextension/select-magnifier-c-inventory-by-magnifier-criterion',  parameter);

