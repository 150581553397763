export const PAGER = {
  MAX_PAGE_NUM: '1',
  MIN_PAGE_NUM: '1',
  MAX_PAGE_SIZE: '500',
  MIN_PAGE_SIZE: '1',
  MAX_EXPORT_PAGE_SIZE: '29999'
}

export const SET_QUERY_SHOW_KEY_SPLIT = ';'

export const HEAD_FOOT_CODE = {
  'headCode':'head',
  'rowCode':'line',
  'head':'头',
  'line':'行'
}

export const SEARCH_FORM_NUMRANGE = [
  {
    value:'GREATER_THAN',
    label:'大于'
  },
  {
    value:'GREATER_THAN_OR_EQUAL',
    label:'大于等于'
  },
  {
    value:'LESS_THAN',
    label:'小于'
  },
  {
    value:'LESS_THAN_OR_EQUAL',
    label:'小于等于'
  },
  {
    value:'NOT_EQUAL_SINGLE_VALUE',
    label:'不等于'
  },
  {
    value:'SINGLE_VALUE',
    label:'等于'
  },
]

export const TABLE_COLUMN_COMMON =  ['index','select','op','status', 'seq', 'checkbox', 'edit']



