<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-select v-model="searchMenu" filterable remote clearable placeholder="请输入关键词搜索菜单" :remote-method="remoteMethod" >
        <el-option v-for="(item, index) in menus" :key="'menu' +index" :label="item.meta.title" :value="item.path"> </el-option>
      </el-select>
      <el-menu
        :default-active="searchMenu"
        :collapse="isCollapse"
        :unique-opened="false"
        :collapse-transition="false"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :active-text-color="variables.menuActiveText"
        mode="vertical"
      >
        <sidebar-item v-for="route in permission_routes" :key="route.path" v-if="!route.hidden" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/sass/variables.scss'
import {constantRoutes} from '@/router/index.js'
import {recursion} from "../../../utils";

export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      searchMenu: '',
      menus: [],
      fixdMenus: [],
      permissionMenus:[]
    }
  },
  methods: {
    /**
     * @description 目录关键词查询
    */
    remoteMethod(keyWord) {
      if (keyWord !== '') {
        this.menus = this.fixdMenus.filter(item => {
          return item.meta.title.indexOf(keyWord) > -1;
        });
      } else {
        this.menus = this.fixdMenus;
      }
    },
    getMenusData(){
      this.fixdMenus = recursion(this.permission_routes);
      this.permissionMenus = JSON.parse(window.sessionStorage.MENU_DATA);
    },
  },
  created() {
     this.getMenusData();
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    permission_routes(){
      const routes = JSON.parse(JSON.stringify(constantRoutes))
      return routes.filter(item => {
        if(this.permissionMenus.some(child=>child.CODE === item.name)){
          if (item.children && item.children.length > 0) {
            return item.children.filter(item => {
              const hasPer = this.permissionMenus.some(child=>child.CODE === item.name)
              item.hidden = item.hidden ? true : hasPer ? false : true;
              return item.hidden
            })
          } else {
            return !item.hidden
          }
        }
      });
    },
    variables() {
      return variables
    },
    showLogo() {
      return true
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>