import request from '@/utils/request';

export const GetClientById = parameter => request.get('/client/get-client-by-id', { params: parameter });

export const GetClientList = parameter => request.post('/client/select-client-by-client',  parameter);

export const GetClientGroup = parameter => request.get('/client/select-client-group-by-name-page',  {params: parameter});


export const GetPiece = parameter => request.get('/client/select-client-group-by-name-page',  {params: parameter});

//启用更新client
export const startClientList = parameter => request.post('/client/update-able-client',  parameter);

//停用 client
export const stopClientList = parameter => request.post('/client/update-pending-client',  parameter);

//更新client
export const updateClientData = parameter => request.post('/client/update-client',  parameter);

//新增client
export const addClientData = parameter => request.post('/client/create-client',  parameter);

//取消停用client
export const cancelPendingClientData = parameter => request.post('/client/update-cancel-pending-client',  parameter);

// 导出
export const ExportClientData = parameter => request.post('/client/export-client-by-criterion',  parameter, { responseType:'blob' });


export const getActiveClientGroups = parameter => request.post('/client/select-active-client-group',  parameter);
