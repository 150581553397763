/*
 * @Description: 
 * @Version: v0.0.1
 * @Autor: syl
 * @Date: 2020-10-13 09:33:59
 * @LastEditors: syl
 * @LastEditTime: 2020-10-26 10:19:14
 */
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import MockRouter from './modules/mock';
import ClientRouter from './modules/client';
import existingDataRouter from './modules/existingData';
import HistoryDataRouter from './modules/historyData';
import AuthRouter from './modules/authData';
import PasswordManage from './modules/passwordManage';


/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '*',
    redirect: '/login',
    hidden: true,
    meta: { title: 'redirect'},
  },
  {
    path: '/login',
    component: () => import('@/pages/login/login.vue'),
    hidden: true,
    meta: { title: 'Login'},
  },
  {
    path: '/layout',
    component: Layout,
    name: 'layout',
    hidden: true,
    meta: { title: 'layout', icon: '' },
    children: [
      {
        path: '/home',
        component: () => import('@/pages/dashboard/index'),
        name: 'Home',
        meta: { title: 'Home', icon: '', affix: true }
      }
    ]
  },
  MockRouter,

  ClientRouter,
  existingDataRouter,
  HistoryDataRouter,
  AuthRouter,
  PasswordManage
];

export const infoRoutes = [

];


export default new Router({
  routes: [...constantRoutes,...infoRoutes]
})
