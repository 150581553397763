import Vue from "vue";


export default {
  inserted: function(el, binding) {
    // console.log(binding,"w")
    if (!Vue.prototype.$_has(binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
}

