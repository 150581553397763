
/**
 * @description 组件注册, 在根目录下执行以下命令
 * @command npm run components || yarn components
 * */
import Vue from 'vue';

Vue.component('breadcrumb', () => import('./breadcrumb/breadcrumb.vue'));
Vue.component('comConfigTable', () => import('./comConfigTable/comConfigTable.vue'));
Vue.component('comDialog', () => import('./comDialog/comDialog.vue'));
Vue.component('comForm', () => import('./comForm/comForm.vue'));
Vue.component('comFunctionRender', () => import('./comFunctionRender/comFunctionRender.vue'));
Vue.component('comMagnifier', () => import('./comMagnifier/comMagnifier.vue'));
Vue.component('comAutocomplete', () => import('./comAutocomplete/comAutocomplete.vue'));
Vue.component('comNumRange', () => import('./comNumRange/comNumRange.vue'));
Vue.component('comPage', () => import('./comPage/comPage.vue'));
Vue.component('comPagination', () => import('./comPagination/comPagination.vue'));
Vue.component('comTable', () => import('./comTable/comTable.vue'));
Vue.component('configFilterTable', () => import('./configFilterTable/configFilterTable.vue'));
Vue.component('hamburger', () => import('./hamburger/hamburger.vue'));
Vue.component('rightPanel', () => import('./rightPanel/rightPanel.vue'));
Vue.component('screenfull', () => import('./screenfull/screenfull.vue'));
Vue.component('sizeSelect', () => import('./sizeSelect/sizeSelect.vue'));
Vue.component('svgIcon', () => import('./svgIcon/svgIcon.vue'));
Vue.component('textEllipsis', () => import('./textEllipsis/textEllipsis.vue'));
Vue.component('themePicker', () => import('./themePicker/themePicker.vue'));
Vue.component('wmsDialog', () => import('./wmsDialog/wmsDialog.vue'));
Vue.component('wmsDropdown', () => import('./wmsDropdown/wmsDropdown.vue'));