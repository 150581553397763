/**
 * @FILE 历史库存
*/

// 库存创建者
export const CREATED_INVENTORYS = [
  { label: '预收货', key: 'P' },
  { label: '收货', key: 'R' },
  { label: '库存调整', key: 'A' },
  { label: '盘点', key: 'S' },
  { label: '套件', key: 'K' },
];