import API from '@api'
import {SET_QUERY_SHOW_KEY_SPLIT} from '@constant'

const state = {
  searchItemList:'',
  tableColumnList:''
}

const types = {
}

const mutations = {
  SET_SEARCH_ITEM_LIST: (state, payload = []) => {
    state.searchItemList = payload.map(item=>item.columnCode).join(SET_QUERY_SHOW_KEY_SPLIT)
  },
  SET_PAGE_COLUMN_LIST:  (state, payload) => {
    state.tableColumnList = payload.TABLE_COLUMNS
  },
}

const actions = {
  async setPageSearchItem({ commit }, payload) {
    const params = { ...payload }
    const {status,message,data} = await API.setPageSearchItem(params)
    if (status === 200) {
      store.commit('CHANGE_TABLE_COLUMNS', params)
    }
  },
  async getPageSearchItem({ commit }, payload) {
    const params = {
      ...payload
    }
    const {status,message,data} = await API.getPageSearchItem(params);
    if(status === 200){
      commit('SET_SEARCH_ITEM_LIST',data)
    }
  },
  async getPageTableColumnItem({ commit }, payload) {
    const params = {
      ...payload
    }
    const {status,message,data} = await API.getPageTableColumnItem(params);
    if(status === 200){
      const result = data || {TABLE_COLUMNS:''}
      commit('SET_PAGE_COLUMN_LIST',result)
    }
  },
}

export default {
  state,
  mutations,
  actions
}
