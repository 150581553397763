import request from '@/utils/request';

//preAdvince 头信息
export const GetPreadviceHeaderList = parameter => request.post('/preadvice/select-pre-advice-header-by-advice-header-vo',  parameter);
//preAdvince 头详情
export const GetPreadviceHeaderInfo = parameter => request.post('/preadvice/get-pre-advice-header-by-pk', parameter);

//preAdvince 行信息
export const GetPreadviceListList = parameter => request.post('/preadvice/select-pre-advice-line-by-criterion-bean-vo',  parameter);
//preAdvince 行详情
export const GetPreadviceLineInfo = parameter => request.post('/preadvice/get-pre-advice-line-by-line-pk',parameter);

//upi头列表
export const GetUPIHeaderList = parameter => request.post('/upi/select-upi-receipt-header-by-criterion-bean-vo',  parameter);
//upi 头详情
export const GetUPIHeaderInfo = parameter => request.get('/upi/get-upi-receipt-header-by-pallet-id', {params:parameter});
//upi行列表
export const GetUPILineList = parameter => request.post('/upi/select-upi-receipt-line-by-criterion-bean-vo',  parameter);
//upi 头详情
export const GetUPILineInfo = parameter => request.get('/upi/get-upi-receipt-line-by-pallet-id', {params:parameter});

//order 头信息
export const GetOrderHeaderList = parameter => request.post('/order/select-order-header-by-order-header-vo',  parameter);
//order 头信息详情
export const GetOrderHeaderInfo = parameter => request.get('/order/get-order-header-by-id', {params:parameter});

//order 行信息
export const GetOrderListList = parameter => request.post('/order/select-order-line-by-criterion-bean-vo',  parameter);
//order 行信息详情
export const GetOrderLineInfo = parameter => request.get('/order/get-order-line-by-header-line-id', {params:parameter});


//consignment列表
export const GetConsignmentList = parameter => request.post('/consignment/select-consignment-by-criterion-bean-vo',  parameter);
//consignment 详情
export const GetConsignmentInfo = parameter => request.get('/consignment/get-consignment-by-consignment-site-id',  parameter);

//VMI transfer列表
export const GetVmiTransferList = parameter => request.post('/vmi/select-vmi-transfer-by-criterion-bean-vo',  parameter);

//出库单详情查询
export const GetShippingManifestInfo = parameter => request.get('/shipping/get-shipping-manifest-by-pk',  {params:parameter});
//出库单查询列表
export const GetShippingManifestList = parameter => request.post('/shipping/select-shipping-manifest-by-criterion-bean-vo',  parameter);

//库存详情查询
export const GetTransactionInfo = parameter => request.get('/inventory/get-inventory-transaction-by-pk',  {params:parameter});
//库存查询列表
export const GetTransactionList = parameter => request.post('/inventory/select-inventory-transaction-by-criterion-bean-vo',  parameter);

//查询移动任务列表
export const GetMoveTaskList = parameter => request.post('/move/select-move-task-inventory-by-criterion-bean-vo',  parameter);
//移动任务详情查询
export const GetMoverTaskInfo = parameter => request.get('/move/get-move-task-inventory-by-pk',  {params:parameter});

/**
 * @description invPallat
*/
// 查询库存托盘数
export const GetCextensionList = parameter => request.post('/cextension/select-shipping-manifest-by-criterion-bean-vo',  parameter);
// updateInvPalCount 更新接口
export const UpdateInvPalCount = parameter => request.post('/cextension/update-invpalcount',  parameter);
// 导出
export const ExportCInvPalCount = parameter => request({url:'/cextension/export-c-inv-pal-count',method:'post',params:parameter,responseType:'blob'});


