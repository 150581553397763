import API from '@api'
import { SET_QUERY_SHOW_KEY_SPLIT } from '@constant'

const state = {
  allSearchItems: {},
  allTableColumes: {}
}

const mutations = {
  SET_ALL_SEARCH_ITEM_LIST: (state, payload = []) => {
    state.allSearchItems = payload;
    window.sessionStorage.ALL_SEARCH_ITEM_LIST = JSON.stringify(payload);
  },
  SET_ALL_TABLE_COLUMNS:  (state, payload) => {
    state.allTableColumes = payload;
    window.sessionStorage.ALL_TABLE_COLUMNS = JSON.stringify(payload);
  },
  CHANGE_SEARCH_ITEM_LIST: (state, payload = []) => {
    state.allSearchItems[payload.TABLE_NAME] = payload.TABLE_COLUMNS;
    
    let result = JSON.parse(window.sessionStorage.getItem('ALL_SEARCH_ITEM_LIST'));
    result[payload.TABLE_NAME] = payload.TABLE_COLUMNS;
    window.sessionStorage.ALL_SEARCH_ITEM_LIST = JSON.stringify(result);
  },
  CHANGE_TABLE_COLUMNS: (state, payload) => {
    state.allTableColumes[payload.TABLE_NAME] = payload.TABLE_COLUMNS;

    let result = JSON.parse(window.sessionStorage.getItem('ALL_TABLE_COLUMNS'));
    result[payload.TABLE_NAME] = payload.TABLE_COLUMNS;
    window.sessionStorage.ALL_TABLE_COLUMNS = JSON.stringify(result);
  }
}

const actions = {
  async changehabit({ commit }, payload) {
    const params = { ...payload };
    const {status,message,data} = await API.setPageSearchItem(params);
    if (status === 200) {
      if (params.TYPE === "QUERY") {
        commit('CHANGE_SEARCH_ITEM_LIST', params)
      } else {
        commit('CHANGE_TABLE_COLUMNS', params)
      }
      
    }
  },
  async getAllPageSearchItem({ commit }, payload) {
    const params = { ...payload }
    const { status, message, data } = await API.getAllPageSearchItem(params);
    if (status === 200) {
      let result = {};
      Object.keys(data).forEach(tableName => {
        result[tableName] = data[tableName].map(item => item.columnCode).join(SET_QUERY_SHOW_KEY_SPLIT)
      })
      commit('SET_ALL_SEARCH_ITEM_LIST', result)
    }
  },
  async getAllPageTableColumnItem({ commit }, payload) {
    const params = { ...payload }
    const { status, message, data } = await API.getAllPageTableColumnItem(params);
    if(status === 200){
      let result = {};
      Object.keys(data).forEach(tableName => {
        let column = data[tableName].TABLE_COLUMNS;
        if (column.indexOf('edit') < 0) {
          column = column + ';edit'
        }
        result[tableName] = column
      })
      commit('SET_ALL_TABLE_COLUMNS', result);
    }
  },
}

export default {
  state,
  mutations,
  actions
}
