export const VMI_STATUS = [
  { label: '完成', key: 'Complete'  },
  { label: '错误', key: 'Error'  },
  { label: '保留', key: 'Hold'  },
  { label: '进行中', key: 'In_Progress'  },
  { label: '发布', key: 'Released'  },
];

export const VMI_TYPE = [
  { label: '接收', key: 'Receipt' },
  { label: '发运', key: 'shipment' }
];