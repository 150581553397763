import request from '@/utils/request';
//查询已配置的查询条件
export const getPageSearchItem = parameter => request.get('/tblcolumn/find-query-columns-by-user-table', { params: parameter });
//查询已配置的查询表格列
export const getPageTableColumnItem = parameter => request.get('/tblcolumn/get-show-columns-by-user-table', { params: parameter });
//设置条件
export const setPageSearchItem = parameter => request.post('/tblcolumn/create-user-tbl-column', parameter);
//site列表
export const setSelectSiteList = parameter => request.post('/site/select-site-by-site-vo-page', parameter);
///owner列表
export const setSelectOwnerList = parameter => request.post('/owner/select-owner-by-owner-vo-page',  parameter);
//terms列表
export const setTermsOwnerList = parameter => request.get('/terms/select-terms-of-delivery-by-currency-page',  { params: parameter});
//selectSkuByClientSkuId
export const setSkuConfigIdList = parameter => request.get('/sku/select-sku-by-client-sku-id',  { params: parameter});
//setSelectSamplingList
export const setSelectSamplingList = parameter => request.get('/sampling/select-sample-type-by-currency-page',  { params: parameter});
//client列表
export const setSelectClientList = parameter => request.post('/client/select-client-by-client-vo-page', parameter);
//pallet 列表
export const setSelectPalletList = parameter => request.post('/pallet/select-pallet-config-by-currency-page', parameter);
//condition 列表
export const setSelectConditionList = parameter => request.post('/condition/select-condition-code-by-condition-code', parameter);
//origin 列表
export const setSelectOriginList = parameter => request.post('/origin/select-origin-by-origin', parameter);
//Lock_Code 列表
export const setSelectInventoryLockCodeList = parameter => request.post('/inventory/select-inventory-lock-code-by-condition-code', parameter);
//location 列表
export const setLocationList = parameter => request.post('/location/select-location-by-location-vo-page', parameter);
//location 列表
export const setSkuList = parameter => request.post('/sku/select-sku-by-sku-vo-page', parameter);
//condition 列表
export const setConditionList = parameter => request.post('/condition/select-condition-code-by-condition-code-vo-page', parameter);



//通过sku client 去查备注
export const getSkuDesc = parameter => request.post('/sku/get-sku-by-pk', parameter);



// 查询已配置全部的查询条件
export const getAllPageSearchItem = parameter => request.get('/tblcolumn/find-all-query-columns-by-user-table-new', { params: parameter });
// 询已配置全部的表格列
export const getAllPageTableColumnItem = parameter => request.get('/tblcolumn/find-all-show-columns-by-user-table-new', { params: parameter });