export const EMUN_OPERATOR = {
  SINGLE_VALUE:'SINGLE_VALUE',
  NOT_SINGLE_VALUE:'NOT_SINGLE_VALUE',
  EMPTY:'EMPTY',
  NOT_EMPTY:'NOT_EMPTY',
  GREATER_THAN:'GREATER_THAN',
  LESS_THAN:'LESS_THAN',
  BETWEEN_VALUE:'BETWEEN_VALUE',
  NOT_BETWEEN_VALUE:'NOT_BETWEEN_VALUE'
}

export const EMUN_OPERATOR_VALUE_TEMPLATE = {
  left:'$left$',
  right:'$right$'
}

/*
* selectDataType => multiple | 多选 ; empty | 为空 ; single | 多选
* */
export const OPERATOR_SEARCH = [
  {
    value:EMUN_OPERATOR.SINGLE_VALUE,
    label:'等于',
    template:`=${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    selectDataType:'multiple'
  },
  {
    value:EMUN_OPERATOR.NOT_SINGLE_VALUE,
    label:'不等于',
    template:`<>${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    selectDataType:'single'
  },
  {
    value:EMUN_OPERATOR.EMPTY,
    label:'空',
    template:`=${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    selectDataType:'empty'
  },
  {
    value:EMUN_OPERATOR.NOT_EMPTY,
    label:'不为空',
    template:`<>${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    selectDataType:'empty'
  },
  {
    value:EMUN_OPERATOR.GREATER_THAN,
    label:'大于',
    template:`>${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    selectDataType:'single'
  },
  {
    value:EMUN_OPERATOR.LESS_THAN,
    label:'小于',
    template:`<${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    selectDataType:'single'
  },
  {
    value:EMUN_OPERATOR.BETWEEN_VALUE,
    label:'之间...和...',
    template:`>${EMUN_OPERATOR_VALUE_TEMPLATE.left}<${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    isRadio:true,
    selectDataType:'single'
  },
  {
    value:EMUN_OPERATOR.NOT_BETWEEN_VALUE,
    label:'不介于...和...',
    template:`<${EMUN_OPERATOR_VALUE_TEMPLATE.left}>${EMUN_OPERATOR_VALUE_TEMPLATE.right}`,
    isRadio:true,
    selectDataType:'single'
  },
]





