import Layout from '@/layout'

const mockRouter = {
  path: '/clientManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'clientManage',
  meta: {
    title: 'Client 管理',
    icon: 'chart'
  },
  children: [
    {
      path: '/client',
      component: () => import('@pages/client/client.vue'),
      name: 'Client',
      meta: { title: 'Client' }
    },
    {
      path: '/clientDetail',
      component: () => import('@pages/client/blocks/clientDetail/clientDetail.vue'),
      name: 'clientDetail',
      hidden: true,
      meta: { title: 'clientDetail' }
    }
  ]
}

export default mockRouter