
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
/**
 * 过滤
 * @param dataArray
 * @param func
 * @returns {*}
 */
export const isFilter = function(dataArray, func) {
   if (!dataArray || dataArray.length === 0) {
       return null;
   }
   let result = [];
   for (let i = 0; i < dataArray.length; i++) {
       if (func && isFunction(func) && func(dataArray[i], i)) {
           result.push(dataArray[i]);
       }
   }
   return result.length === 1 ? result[0] : result;
}

/**
 * 判断是否为方法
 * @param fn
 * @returns {boolean}
 */
export const isFunction = function(fn) {
  const string = toString.call(fn);
  return (
      string === "[object Function]" ||
      (typeof fn === "function" && string !== "[object RegExp]") ||
      (typeof window !== "undefined" &&
        // IE8 and below
        (fn === window.setTimeout ||
          fn === window.alert ||
          fn === window.confirm ||
          fn === window.prompt))
  );
}

/**
 *x
 */
export const createTimeoutPromise = (data, time = 2000) => {
    return new Promise(resolve => {
        setTimeout(() => resolve(data), time);
    });
}

/**
 * 全局loading
 */
export const createFullLoading = vm => {
    let loading =
        vm &&
        vm.$loading({
            lock: true,
            text: "请稍等...",
            spinner: "el-icon-loading",
            background: "hsla(0,0%,100%,.9)",
            customClass: "show-loading"
        });
    return loading;
}