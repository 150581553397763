import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import store from './store'
import router from './router'
import './sass/element-variables.scss'
import './sass/index.scss'

import './pluginConfig/vxeTable';

//判断权限点的方法
Vue.prototype.$_has = function(value) {
  let isExit = false;
  let permsStrList = JSON.parse(sessionStorage.getItem("PERMISSION_LIST"));
  if (!Array.isArray(permsStrList)) {
    return false;
  }
  isExit = permsStrList.some(item=>item.CODE === value)
  return isExit;
};


import './directives'
import './components'
import {isCanShowBlock} from "./utils";

Vue.use(Element)


Vue.config.productionTip = false

Vue.prototype.isCanShowBlock = isCanShowBlock


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
