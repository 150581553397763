import { CONSIGNMENT_STATUS } from './consignment'

export const INV_TRAN_FROM_STATUS = [
  { label: '错误', key: 'Error' },
  { label: '悬而未决', key: 'Pending' },
  ...CONSIGNMENT_STATUS
]

export const INV_TRAN_LOCK_STATUS = [
  { label: '已锁', key: 'Locked' },
  { label: '未锁', key: 'UnLocked' },
]

export const INV_TRAN_QC_STATUS = [
  { label: 'qc 保留', key: 'Hold' },
  { label: 'qc 释放', key: 'Released' },
  { label: 'qc 拒绝', key: 'Rejected' },
  { label: '没有批次控制', key: '' }
]