import { constantRoutes } from '@/router'
import { getUserRoleResources } from "../../api/auth";
import { removeAuthInfo } from "../../utils";

const state = {
  routes: constantRoutes
}

const mutations = {

}

const actions = {
  logoutData() {
    removeAuthInfo()
  },
  async getUserPermissionData() {
    const params = {}
    const { data, status, message } = await getUserRoleResources(params)
    return { data, status, message }
  }
}

export default {
  state,
  mutations,
  actions
}
