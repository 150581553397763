import Layout from '@/layout'

const mockRouter = {
  path: '/auth',
  component: Layout,
  redirect: 'noRedirect',
  name: 'auth',
  meta: {
    title: '权限管理',
    icon: 'chart'
  },
  children: [
    {
      path: '/roleManage',
      component: () => import('@pages/auth/roleManage/role.vue'),
      name: 'roleManage',
      meta: { title: '角色管理' }
    },
    {
      path: '/userManage',
      component: () => import('@pages/auth/userManage/user.vue'),
      name: 'userManage',
      meta: { title: '用户管理' }
    },
    {
      path: '/wxuserManage',
      component: () => import('@pages/auth/wxUserManage/wxUser.vue'),
      name: 'wxuserManage',
      meta: { title: '微信用户管理' }
    }]
}

export default mockRouter