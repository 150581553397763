import Layout from '@/layout'

const mockRouter = {
  path: '/password',
  component: Layout,
  redirect: 'noRedirect',
  name: 'password',
  meta: {
    title: '密码管理',
    icon: 'chart'
  },
  children: [
    {
      path: '/changePwd',
      component: () => import('@pages/password/changePwd/changePwd.vue'),
      name: 'changePwd',
      meta: { title: '密码修改' }
    },
    {
      path: '/resetPwd',
      component: () => import('@pages/password/resetPwd/resetPwd.vue'),
      name: 'resetPwd',
      meta: { title: '重置密码' }
    },
  ]
}

export default mockRouter