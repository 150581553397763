const accessToken = sessionStorage.getItem("access_token");

/**
 * @description 调用 ewmsprint jar包 中 ewmsprint.printReport() 方法 进行打印 
 * @param {string} printerName 打印机的名称
 * @param {string} reportName 报表名字
 * @param {string} printData 打印信息
 * @param {string} printNum 打印份数
*/
export const print = function (printerName, reportName, printData, printNum) {
  let reportsInfo = new Array();
  reportsInfo[0] = new Array();
  reportsInfo[0][0] = printerName;
  reportsInfo[0][1] = '[{"reportName":"' + reportName + '","reportParams":{"wb_no":"' + printData + '"}}]';
  reportsInfo[0][2] = printNum; 
  reportsInfo[0][3] = 'false';  

  /**
   * @description 该 ewmsprint.printReport() 接收两个参数
   * @param {string} accessToken 验证信息
   * @param {Array<Array<string>>} reportsInfo 二维数组
   *        reportsInfo[0][0] // 二维数组第一个元素为打印机的名称,必须跟本地打印机名字完全一致
   *        reportsInfo[0][1] // 第二个元素为报表的信息
   *        reportsInfo[0][2] // 第三个元素为打印份数
   *        reportsInfo[0][3] // 第四个参数为是否显示打印
   * @return {boolean}
  */
  if (ewmsprint.printReport(accessToken, reportsInfo)) {
    this.$confirm('打印任务已到达打印队列！', '系统提示', {
      confirmButtonText: '确定',
      type: 'warning'
    })
  }
}

/**
 * @description 网页内容打印
*/
export const web_print = function () {
  let newWindow = window.open('', "newWindow");   // 打开新窗口
  let codestr = document.getElementById("pdf-wrap").innerHTML;   // 获取需要生成pdf页面的div代码
  newWindow.document.write(codestr);   // 向文档写入HTML表达式或者JavaScript代码
  newWindow.document.close();     // 关闭document的输出流, 显示选定的数据
  newWindow.print();   // 打印当前窗口
  newWindow.close();
  return true;
}