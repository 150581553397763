import {
  CInventoryMagnifierList,
  clientMagnifierList,
  consignmentMagnifierList, inventoryMagnifierList, moveTaskMagnifierList,
  orderHeaderMagnifierList,
  orderLineMagnifierList,
  preHeaderMagnifierList,
  preLineMagnifierList,
  shipmentMagnifierList,
  upiHeaderMagnifierList,
  upiLineMagnifierList,
  vmiMagnifierList
} from "../../api/comMagnifier";


const MAX_SIZE_NUM = {
  "PAGE_NUM": 1,
  "PAGE_SIZE": 100000,
}


const state = {}


function setCommonParams(key, value){
  const params = {
    CRITERION_MAP: {},
    ...MAX_SIZE_NUM,
    CRITERION_SORT_MAP: {},
    SHOW_COLUMN_LIST: [key]
  }
  params.CRITERION_MAP[key] = value
  params.CRITERION_SORT_MAP[key] = 'asc'
  return params
}

const actions = {
  async CLIENT({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await clientMagnifierList(params)
  },
  async ORDER_HEADER({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await orderHeaderMagnifierList(params)
  },
  async ORDER_LINE({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await orderLineMagnifierList(params)
  },
  async PRE_ADVICE_HEADER({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await preHeaderMagnifierList(params)
  },
  async PRE_ADVICE_LINE({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await preLineMagnifierList(params)
  },
  async UPI_HEADER({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await upiHeaderMagnifierList(params)
  },
  async UPI_LINE({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await upiLineMagnifierList(params)
  },
  async VMI_TRANSFER({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await vmiMagnifierList(params)
  },
  async CONSIGNMENT({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await consignmentMagnifierList(params)
  },
  async SHIPMENT_MANIFEST({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await shipmentMagnifierList(params)
  },
  async MOVE_TASK({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await moveTaskMagnifierList(params)
  },
  async INVENTORY_TRANSACTION({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await inventoryMagnifierList(params)
  },
  async C_INVENTORY({commit}, {key, value}) {
    const params = setCommonParams(key, value)
    return await CInventoryMagnifierList(params)
  },
}

export default {
  state,
  actions
}
