export const backApis = [
  '/client/select-magnifier-client-by-magnifier-criterion',
  '/order/select-magnifier-order-line-by-magnifier-criterion',
  '/order/select-magnifier-order-header-by-magnifier-criterion',
  '/preadvice/select-magnifier-pre-advice-header-by-magnifier-criterion',
  '/preadvice/select-magnifier-pre-advice-line-by-magnifier-criterion',
  '/upi/select-magnifier-upi-receipt-header-by-magnifier-criterion',
  '/upi/select-magnifier-upi-receipt-line-by-magnifier-criterion',
  '/vmi/select-magnifier-vmi-transfer-by-magnifier-criterion',
  '/consignment/select-magnifier-consignment-by-magnifier-criterion',
  '/shipping/select-magnifier-shipping-manifest-by-magnifier-criterion',
  '/move/select-magnifier-move-task-inventory-by-magnifier-criterion',
  '/inventory/select-magnifier-inventory-transaction-by-magnifier-criterion'
]