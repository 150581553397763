import Layout from '@/layout'

const mockRouter = {
  path: '/historyDataManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'historyDataManage',
  meta: {
    title: '数据查询',
    icon: 'chart'
  },
  children: [
    {
      path: '/order',
      component: () => import('@pages/historyData/order/index.vue'),
      name: 'order',
      meta: { title: 'order查询' }
    },
    {
      path: '/orderHeadDetail',
      component: () => import('@/pages/historyData/order/blocks/detail/detail.vue'),
      hidden: true,
      name:'orderHeadDetail',
      meta: { title: 'orderHeadDetail'},
    },
    {
      path: '/orderLineDetail',
      component: () => import('@/pages/historyData/order/blocks/lineDetail/detail.vue'),
      hidden: true,
      name:'orderLineDetail',
      meta: { title: 'orderLineDetail'},
    },
    {
      path: '/preAdvice',
      component: () => import('@pages/historyData/preAdvice/index.vue'),
      name: 'preAdvice',
      meta: { title: 'Pre-advice 查询' }
    },
    {
      path: '/preAdviceHeadDetail',
      component: () => import('@/pages/historyData/preAdvice/blocks/detail/detail.vue'),
      hidden: true,
      name:'preAdviceHeadDetail',
      meta: { title: 'preAdviceHeadDetail'},
    },
    {
      path: '/preAdviceLineDetail',
      component: () => import('@/pages/historyData/preAdvice/blocks/lineDetail/detail.vue'),
      hidden: true,
      name:'preAdviceLineDetail',
      meta: { title: 'preAdviceLineDetail'},
    },
    {
      path: '/UPI',
      component: () => import('@pages/historyData/upi/index.vue'),
      name: 'UPI',
      meta: { title: 'UPI 查询' }
    },
    {
      path: '/UPIHeadDetail',
      component: () => import('@/pages/historyData/upi/blocks/detail/detail.vue'),
      hidden: true,
      name:'UPIHeadDetail',
      meta: { title: 'UPIHeadDetail'},
    },
    {
      path: '/UPILineDetail',
      component: () => import('@/pages/historyData/upi/blocks/lineDetail/detail.vue'),
      hidden: true,
      name:'UPILineDetail',
      meta: { title: 'UPILineDetail'},
    },
    {
      path: '/vmiTransfer',
      component: () => import('@pages/historyData/vmiTransfer/vmiTransfer.vue'),
      name: 'vmiTransfer',
      meta: { title: 'VMI Transfer 表' }
    },
    {
      path: '/vmiTransferDetail',
      component: () => import('@/pages/historyData/vmiTransfer/blocks/detail/vmiTransferDetail.vue'),
      name: 'vmiTransferDetail',
      hidden:true,
      meta: { title: 'vmiTransferDetail' }
    },
    {
      path: '/consignment',
      component: () => import('@pages/historyData/consignment/consignment.vue'),
      name: 'consignment',
      meta: { title: 'consignment 表' }
    },
    {
      path: '/consignmentDetail',
      component: () => import('@pages/historyData/consignment/blocks/detail/consignmentDetail.vue'),
      name: 'consignmentDetail',
      hidden: true,
      meta: { title: 'consignmentDetail' }
    },
    {
      path: '/transaction',
      component: () => import('@pages/historyData/transaction/transaction.vue'),
      name: 'transaction',
      meta: { title: '库存事务' }
    },
    {
      path: '/transactionDetail',
      component: () => import('@/pages/historyData/transaction/blocks/detail/transactionDetail.vue'),
      name: 'transactionDetail',
      hidden: true,
      meta: { title: 'transactionDetail' }
    },
    {
      path: '/shipping',
      component: () => import('@pages/historyData/shipping/shipping.vue'),
      name: 'shipping',
      meta: { title: '出库单' }
    },
    {
      path: '/shippingDetail',
      component: () => import('@/pages/historyData/shipping/blocks/detail/shippingDetail.vue'),
      name: 'shippingDetail',
      hidden: true,
      meta: { title: '出库单详情' }
    },
    {
      path: '/invHistory',
      component: () => import('@pages/historyData/invHistory/invHistory.vue'),
      name: 'invHistory',
      meta: { title: '库存查询' }
    },
    {
      path: '/invTotalHistory',
      component: () => import('@pages/historyData/invTotalHistory/invHistory.vue'),
      name: 'invTotalHistory',
      meta: { title: '库存汇总查询' }
    },
    {
      path: '/invHistoryDetail',
      component: () => import('@/pages/historyData/invHistory/blocks/detail/invHistory.vue'),
      name: 'invHistoryDetail',
      hidden: true,
      meta: { title: '库存查询详情' }
    },
    {
      path: '/invPallet',
      component: () => import('@pages/historyData/invPallet/invPallet.vue'),
      name: 'invPallet',
      meta: { title: '库存托盘数查询' }
    }
  ]
}

export default mockRouter