export const PRE_ADVICE_MODE_OF_TRANSPORT = [
  { label: 'Air', key: 'A' },
  { label: 'Road', key: 'R' },
  { label: 'Sea', key: 'S' },
  { label: 'Rail', key: 'T' },
];

export const PRE_ADVICE_STATUS = [
  { label: '完成', key: 'Complete', tLabel: 'WLKComplete' },
  { label: '保留', key: 'Hold', tLabel: 'WLKHold' },
  { label: '进行中', key: 'In Progress', tLabel: 'WLKIn Progress' },
  { label: '预接收', key: 'Pre-Received', tLabel: 'WLKPre-Received' },
  { label: '发布', key: 'Released', tLabel: 'WLKReleased' },
];