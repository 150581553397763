import request from '@/utils/request';

//角色列表
export const getRoleLists = parameter => request.get('/auth/select-user-group-list', {params:parameter});
//用户列表
export const getUserLists = parameter => request.get('/application/select-application-user-list', {params:parameter});

//根据角色查询菜单
export const getRoleMenus = parameter => request.get('/auth/get-role-menus', {params:parameter});

//查询用户绑定的角色列表
export const getUserRoleList = parameter => request.get('/auth/get-user-roles', {params:parameter});

//查询用户绑定的角色列表
export const updateUserRoleList = parameter => request.post('/auth/update-user-roles', parameter);

//查询角色绑定的资源
export const getRoleResources = parameter => request.get('/application/get-role-resources', {params:parameter});

//查询角色绑定的资源
export const updateRoleResources = parameter => request.post('/application/update-role-resources', parameter);

//查询用户绑定的资源
export const getUserRoleResources = parameter => request.get('/auth/get-user-permissions', {params:parameter});
//添加用户
export const addUser = parameter => request.post('/application/add-user',parameter);
//查询微信用户
export const getWxUserLists = parameter => request.post('/wxuser/select-wxuser-list', parameter);
//微信绑定用户
export const bindWxUser = parameter => request.post('/wxuser/update-wxuser-bypk', parameter);