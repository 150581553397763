export const SHIPMENT_LOCK_STATUS = [
  { label: '已锁', key: 'Locked' },
  { label: '未锁', key: 'UnLocked' },
]

export const SHIPMENT_STATUS = [
  { label: '订单关闭', key: 'Closed' },
  { label: '容器关闭', key: 'Container Closed' },
  { label: '已打印标签', key: 'Lebelled' },
  { label: '容器未关闭', key: 'Open' },
  { label: '包裹打包', key: 'Packed' },
  { label: '托盘关闭', key: 'Paller Closed' },
  { label: '订单打包', key: 'Repacked' },
]

