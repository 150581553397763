/* eslint-disable one-var */
const jsonC = {}.constructor,
  isJSON = function (json) {
    return Boolean(json && json.constructor === jsonC);
  };
export const mergeJSON = function (json1, json2) {
  let result = null;
  if (isJSON(json2)) {
    result = {};
    if (isJSON(json1)) {
      for (let key in json1) {
        if (!Object.prototype.hasOwnProperty.call(json1, key)) {
          continue;
        }
        result[key] = json1[key];
      }
    }
    for (let key in json2) {
      if (!Object.prototype.hasOwnProperty.call(json2, key)) {
        continue;
      }
      if (typeof result[key] === "object" && typeof json2 === "object") {
        result[key] = mergeJSON(result[key], json2[key]);
      } else {
        result[key] = json2[key];
      }
    }
  } else if (Array.isArray(json1) && Array.isArray(json2)) {
    result = json1;
    for (let i = 0; i < json2.length; i++) {
      if (result.indexOf(json2[i]) === -1) {
        result[result.length] = json2[i];
      }
    }
  } else {
    result = json2;
  }
  return result;
};
