import Layout from '@/layout'

const mockRouter = {
  path: '/mock',
  component: Layout,
  redirect: 'noRedirect',
  name: 'mock',
  meta: {
    title: 'Mock',
    icon: 'chart'
  },
  children: [
    {
      path: '/form',
      component: () => import('@pages/form/form.vue'),
      name: 'Form',
      meta: { title: 'Form' }
    },
    {
      path: '/table',
      component: () => import('@pages/table/table.vue'),
      name: 'Table',
      meta: { title: 'Table' }
    },
    {
      path: '/example',
      component: () => import('@pages/example/example.vue'),
      name: 'Example',
      meta: { title: 'Example' }
    },
    {
      path: '/mo',
      component: () => import('@pages/mock/mock.vue'),
      name: 'Mo',
      meta: { title: 'Mo' }
    }
  ]
}

export default mockRouter