import Layout from '@/layout'

const mockRouter = {
  path: '/existingDataManage',
  component: Layout,
  redirect: 'noRedirect',
  name: 'existingDataManage',
  meta: {
    title: '现有数据查询',
    icon: 'chart'
  },
  children: [
    {
      path: '/moveTask',
      component: () => import('@pages/existingData/moveTask/moveTask.vue'),
      name: 'moveTask',
      meta: { title: '移动任务查询' }
    },
    {
      path: '/moveTaskDetail',
      component: () => import('../../pages/existingData/moveTask/blocks/detail/moveTaskDetail.vue'),
      name: 'moveTaskDetail',
      hidden:true,
      meta: { title: 'moveTaskDetail' }
    },
    // {
    //   path: '/inventoryTransaction',
    //   component: () => import('@pages/existingData/inventoryTransaction/inventoryTransaction.vue'),
    //   name: 'InventoryTransaction',
    //   meta: { title: '库存事务' }
    // }
  ]
}

export default mockRouter