import { export_json_to_excel } from '@export/export2Excel'

/**
 *  @description 格式数据
 *  @filterVal  格式头
 *  @tableData  用来格式的表格数据
 */
function formatJson (filterVal, tableData) {
  return tableData.map(v => {
      return filterVal.map(j => {
              return v[j]
          })
      }
  )
}

/**
 * @description 调用接口进行后台导出 大批量导出
*/
export const exportByApi = function () {}

/**
 * @description 导出当前页面的数据内容
 * @param {String} fileName 文件名称
 * @param {Array<configObject>} tableConfig  表格配置
 * @configObject {String} label 名称
 * @configObject {String} value key
 * 
 * @param {Array<Object>} tableData 表格数据
*/
export const exportByFront = function (fileName, tableConfig, tableData) {
  const tHeader = tableConfig.map(item => item.label);
  const filterVal = tableConfig.map(item => item.value);
  const filename = fileName;
  const data = formatJson(filterVal, tableData);
  export_json_to_excel({
      header: tHeader,
      data,
      filename
  })
}