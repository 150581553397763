export const YN = [
  { label: "是", key: "Y" },
  { label: "否", key: "N" }
]

export const STATUS = [
  { label: '启用', key: '1' },
  { label: '停用', key: '0' },
]

/**
 * @description 时间单位
*/
export const TIME_UOMS = [
  { label: '月', key: 'MONTH' },
  { label: '天', key: 'DAY' },
  { label: '小时', key: 'HOUR' },
  { label: '分钟', key: 'MINUTE' }
];